[class^="bicon-"],
[class*=" bicon-"] {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-top: 1px;
    *margin-right: .3em;
    line-height: 14px;
    vertical-align: text-top;
    background-repeat: no-repeat;
    background-size: 14px 14px;
}

.bicon-new {
    background-image: url(../images/icons/stdi_004.png);
}

.bicon-search {
    background-image: url(../images/icons/stdi_027.png);
}

.bicon-edit {
    background-image: url(../images/icons/stdi_005.png);
}

.bicon-delete {
    background-image: url(../images/icons/stdi_006.png);
}

.bicon-folgepart {
    background-image: url(../images/icons/stdi_003.png);
}

.bicon-folgetabelle {
    background-image: url(../images/icons/stdi_002.png);
}

.bicon-details {
    background-image: url(../images/icons/stdi_018.png);
}

.bicon-removesearch {
    background-image: url(../images/icons/stdi_028.png);
}

.bicon-save {
    background-image: url(../images/icons/stdi_015.png);
}

.bicon-print {
    background-image: url(../images/icons/stdi_007.png);
}

.bicon-cancel {
    background-image: url(../images/icons/stdi_009.png);
}

.bicon-reload {
    background-image: url(../images/icons/stdi_095.png);
}

.bicon-tel {
    background-image: url(../images/icons/stdi_094.png);
}

.bicon-mail {
    background-image: url(../images/icons/stdi_165.png);
}

.bicon-stop {
    background-image: url(../images/icons/stdi_009.png);
}

.bicon-select {
    background-image: url(../images/icons/stdi_050.png);
}